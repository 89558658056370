import ErrorPageComponent from "@components/shared/error-page/error-page";
import MainLayout from "@layouts/main-layout/main-layout";

const Custom404 = () => {
    return (
        <MainLayout>
            <ErrorPageComponent errorStatus={'404. Page not found.'}
                                prefix={`Sorry, there's nothing here. Use the nav above or`}/>
        </MainLayout>
    )
}

export default Custom404;
